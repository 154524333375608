<template>
  <div class="container">
    <ImagesStorage @onImageSelected="onImageSelected" />
    <div class='wysiwyg'>
      <Ckeditor
        :id="editorId"
        ref="ckeditor"
        :config="editorConfig"
        :editor-url="editorUrl"
        :value="value"
        :read-only="disabled"
        class="editor"
        @ready="onEditorReady"
        @input="val => $emit('input', val)"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Ckeditor from 'ckeditor4-vue'
import { wysiwygConfig, url, enableRules, IMAGE_MAX_WIDTH } from '@/ui/wysiwyg/constants'
import { $token } from '@/features/api/common/request'
import { storageVisibilityChanged } from '@/pages/common/media-storage/images-storage.model'
import ImagesStorage from '@/pages/common/media-storage/ImagesStorage.vue'

export default Vue.extend({
  name: 'Wysiwyg',
  components: {
    Ckeditor: Ckeditor.component,
    ImagesStorage,
  },
  props: {
    value: { type: String, required: true, default: '' },
    listenInsertion: { type: Boolean, required: false, default: false },
    listenRightClick: { type: Boolean, required: false, default: false },
    listenSelection: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    editorId: { type: String, required: false },
    editorIndex: { type: Number, required: false, default: 2 },
    placeholder: { type: String, default: '' },
  },
  effector: {
    $token,
  },
  data() {
    return {
      editorUrl: url,
      editorConfig: { ...wysiwygConfig, editorplaceholder: this.value ? '' : this.placeholder },
      editorName: '',
    }
  },
  methods: {
    // getReadStream(mediaURL) {
    //   return fetch(mediaURL.trim()).then(({ body }) => body.getReader())
    // },
    // getCaptionFromURL(mediaURL) {
    //   const splittedURL = mediaURL.trim().split('/')
    //   return splittedURL[splittedURL.length - 1]
    // },
    // async transformStreamIntoFile({ url: mediaUrl, fileType }) {
    //   try {
    //     const streamReader = await this.getReadStream(mediaUrl)
    //     const streamBuffer = await streamReader.read().then(({ done, value }) => ({ done, value }))
    //     const file = new File(streamBuffer.value, this.getCaptionFromURL(mediaUrl), {
    //       type: fileType,
    //     })
    //     return file
    //   } catch (err) {
    //     console.error('ERRORED_WHILE_STREAM_TRANSFORM')
    //   }
    // },
    // parseForMedia(v) {
    //   const exp = /https?:\/\/(.*?)"/gi
    //   const expVideo = /(.mp4|.webm|.avi|.wmv|.mov)/gi
    //   const expImage = /(.jpeg|.jpg|.png)/gi
    //   let URLs = v.match(exp)
    //   let mediaObject = null
    //   if (URLs) {
    //     URLs = URLs.map((urlObj) => {
    //       if (urlObj.match(expVideo)) {
    //         mediaObject = {
    //           url: urlObj.slice(0, -1),
    //           tag: 'video',
    //           fileType: `video/${urlObj.match(expVideo)[0].replace('.', '')}`,
    //         }
    //       } else if (urlObj.match(expImage)) {
    //         mediaObject = {
    //           url: urlObj.slice(0, -1),
    //           tag: 'img',
    //           fileType: `image/${urlObj.match(expImage)[0].replace('.', '')}`,
    //         }
    //       }
    //       return mediaObject
    //     })
    //     return URLs
    //   }
    // },
    // async uploadPictureAndEmit(v) {
    // const mediaObject = this.parseForMedia(v)
    // if (mediaObject) {
    //   mediaObject.map(async (media) => {
    //     try {
    //       const file = await this.transformStreamIntoFile(media)
    //       const fd = new FormData()
    //       fd.append('file', file, file.name)
    //       fd.append('file_type', media.tag)
    //       await fetch(`${config.BACKEND_URL}/api/media-app/media/upload/`, {
    //         method: 'POST',
    //         headers: {
    //           Authorization: `Bearer ${this.$token}`,
    //         },
    //         body: fd,
    //       }).then((r) => r.json())
    //     } catch (err) {
    //       console.error('ERORRED_WHILE_UPLOADING_PICTURE')
    //       console.log(err)
    //     }
    //     return media
    //   })
    // }
    // this.$emit('input', v)
    // },
    onEditorReady(editor) {
      this.editorName = editor.name
      const editable = editor.editable()
      enableRules(editor)
      editor.setData(this.value)
      if (this.listenInsertion) {
        const editorElement = document.querySelector(`#${this.$props.editorId}`)
        editorElement && editorElement.addEventListener('insert', this.handleInsert)
      }
      if (this.listenSelection) this.attachSelectionListening(editor, editable)
      editor.on('fileUploadRequest', (event) => {
        const { xhr } = event.data.fileLoader
        const formData = new FormData()
        xhr.open('POST', event.data.fileLoader.uploadUrl, true)
        xhr.setRequestHeader('authorization', `Bearer ${this.$token}`)
        formData.append('file', event.data.fileLoader.file, event.data.fileLoader.fileName)
        formData.append('file_type', 'image')
        event.data.fileLoader.xhr.send(formData)
        event.stop()
      })
      const editorElement = document.querySelector(`#${this.$props.editorId}`)
      if (editorElement) {
        const sourceBtn = editorElement.getElementsByClassName('cke_button__source')[0]
        sourceBtn.addEventListener('click', () => {
          let val = this.$props.value.replace(/<\/p>\n\n<p/g, '</p><p')
          this.$emit('input', val)
          setTimeout(() => {
            val = this.$props.value.replace(/<\/p><p/g, '</p>\n\n<p')
            this.$emit('input', val)
          }, 10)
        })
      }
      // Open modal window for browse server stored images.
      editor.on('browse', () => {
        storageVisibilityChanged(true)
      })
      editor.on('fileUploadResponse', (event) => {
        event.stop()
        const { data } = event
        const jsonResponse = data.fileLoader.xhr.responseText
        const parsedResponse = JSON.parse(jsonResponse)
        this.$emit('imgId', data.fileLoader.fileName)
        if (!parsedResponse.file) {
          data.message = 'Во время загрузки изображения произошла ошибка'
          event.cancel()
        } else {
          data.url = parsedResponse.file
        }
      })
      editor.on('paste', (event) => {
        const checkArr = event.editor.container.$.innerText
          .split('\n')
          .splice(0, 23)
          .filter((el) => !!el.trim().length)
        const incomingString = event.data.dataValue.split(' ')
        if (incomingString.some((el) => checkArr.some((check) => el.includes(check)))) {
          event.cancel()
        }
        const imgId = incomingString.find(
          (str) => str.includes('id="') && str.indexOf('id="') === 0
        )
        const imgSrc = incomingString.find((str) => str.includes('src="'))
        if (imgId) {
          this.$emit('imgId', imgId.slice(4, imgId.length - 1))
        } else {
          this.$emit('imgSrc', imgSrc)
        }
      })
      editor.on('afterSetData', (event) => {
        this.$emit('linkPaste', event.data.dataValue)
      })
      editor.on('contentDom', () => {
        if (this.listenSelection) this.attachSelectionListening(editor, editor.editable())
      })
      editor.on('beforeDestroy', () => {
        editable.clearListeners()
        editor.removeAllListeners()
      })
      editor.on('contentDomUnload', () => {
        editable.clearListeners()
      })
      this.$emit('instance-ready', editor)
    },
    handleInsert(event) {
      const editor = window.CKEDITOR.instances[this.editorName]
      if (!editor) {
        alert('Editor did not instanced, try to reload page')
        return
      }
      const element = window.CKEDITOR.dom.element.createFromHtml(event.detail)
      editor.insertElement(element)
    },
    attachSelectionListening(editor, editable) {
      editable.attachListener(editable, 'mouseup', async (event) => {
        /* wait for actual range */
        await this.$nextTick()
        const range = editor.getSelectedRanges()
        const isRange = range && range[0].endOffset !== range[0].startOffset
        if (isRange) {
          // parent node and their bounding for setting right position if mouseout happened far from elem
          const parentElementPosition = editor
            .getSelection()
            .getNative()
            .anchorNode.parentNode.getBoundingClientRect()
          // event for position
          const mouseEvent = event.data.$
          if (!parentElementPosition && !mouseEvent) return
          this.$emit('text-selected', { parentElementPosition, mouseEvent, editor })
        }
      })
    },
    getImgSizes(imgUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = () => reject()
        img.src = imgUrl
      })
    },
    async onImageSelected(data) {
      // Open image dialog window.
      document
        .getElementsByClassName('cke cke_focus')[0]
        .querySelector('.cke_button__image_icon')
        .click()
      setTimeout(async () => {
        // Find active dialog window.
        const imageDialogs = document.querySelectorAll('.cke_dialog_container')
        const activeDialog = Object.values(imageDialogs).find(
          (dialog) => dialog.style.display === 'flex'
        )
        const imgDialogInputs = activeDialog.getElementsByClassName('cke_dialog_ui_labeled_content')
        let { width, height } = await this.getImgSizes(data.file)
        // Adjust image sizes.
        if (width > IMAGE_MAX_WIDTH) {
          const ratio = height / width
          width = IMAGE_MAX_WIDTH
          height = Math.floor(width * ratio)
        }
        // Set src attribute to link field.
        imgDialogInputs[0].childNodes[0].childNodes[0].value = data.file
        // Set image width and height.
        imgDialogInputs[2].childNodes[0].childNodes[0].value = width
        imgDialogInputs[3].childNodes[0].childNodes[0].value = height
      }, 200)
    },
  },
  beforeDestroy() {
    const editor = document.querySelector(`#${this.$props.editorId}`)
    if (this.$props.listenInsertion) {
      editor && editor.removeEventListener('insert', this.handleInsert)
    }
  },
})
</script>

<style scoped>
.wysiwyg {
  max-width: 936px;
}
::v-deep .editor {
  table {
    table-layout: fixed;
  }
  p {
    line-height: 1.15;
  }
  th,
  tr,
  td {
    border: 1px solid black;
  }
  li {
    margin-left: 20px;
    padding-left: 5px;
    padding-top: 5px;
  }
  ol > li {
    list-style-type: decimal;
  }
  ul > li {
    list-style-type: disc;
  }
  .cke_chrome {
    background: #edeef0;
    border: none;
    border-radius: 5px;
    padding: 2px;
  }
  .cke_bottom {
    display: none;
  }
  .cke_top {
    padding: 5px 8px;
    background: #fff;
    border-radius: 5px 5px 0 0;
  }
  .cke_contents {
    margin-bottom: 3px;
    padding: 5px;
    padding-top: 1em;
    background-color: transparent;
    border: none;
  }
  .cke_wysiwyg_div {
    margin-top: 0;
    background: transparent;
    /*
      keep outline, it's safari bug workaround https://trello.com/c/rrnWY9Dz
      https://stackoverflow.com/questions/25897883/edit-cursor-not-displayed-on-chrome-in-contenteditable
    */
    outline: 1px solid transparent;
    img {
      max-width: 100%;
    }
  }
  .cke_wysiwyg_div::before {
    opacity: 1;
    margin-top: 0;
  }
  .cke_toolgroup {
    border: 0;
    background-color: transparent;
  }
  .cke_button {
    margin-right: 15px;
  }
  .cke_button_icon {
    opacity: 1;
  }
  .cke_button_on {
    background-color: var(--c-grey-16);
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0px;
  }
  .cke_button_off:hover {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
  }
  .cke_source {
    background-color: transparent;
  }
  /* Visible label for ImagesStorage */
  .cke_button__browseserver_label {
    display: block !important;
  }
}
</style>

<style>
.cke_dialog_contents {
  min-width: 450px;
}
</style>
