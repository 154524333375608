import { createEvent, restore } from 'effector'
import { attach, combine, createEffect, forward, guard, sample } from 'effector-root'
import { UploadMediaResponse } from '@/features/api/media/types'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import { getMediaFx } from '@/features/api/media/get-media'
import {
  defaultGroup,
  LessonsCourseType,
  QuestionsCourseType,
  GroupCourseType,
} from '@/pages/learning/learning-courses/types'
import { editCoursesFx } from '@/features/api/learning/courses/edit-courses'
import { classesDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/classes-dropdown-filter/classes-filter-dropdown.model'
import { UpdateCourseFxParams } from '@/features/api/learning/types'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/multiselectDropdown/subject-dropdown-filter/subject-filter-dropdown.model'
import { lessonsDropdownModule } from '@/pages/learning/learning-courses/edit/parts/lessons-courses/lesson-dropdown.model'
import {
  $selectedCourseTaskCheckType,
  resetCourseCourseTaskCheck,
  setSelectedCourseTaskCheckType,
} from '@/pages/learning/learning-courses/parts/inputs/course-task-check-type/coursesTaskCheckType.model'
import { getCourseFx } from '@/features/api/learning/courses/get-course'
import { mapCourseTaskCheckType } from '@/pages/learning/learning-courses/constants'
import { getRandomId } from '@/pages/common/parts/tasks/utils'
import {
  mapCoursesType,
  setSelectedCourseType,
} from '@/pages/learning/learning-courses/parts/inputs/course-type/coursesType.model'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { sendLessonsFormat } from '@/pages/learning/learning-courses/CourseUtils'
import {
  $addLessonToCourseArr,
  setArrLessonToCourse,
} from '@/pages/learning/learning-lessons/list/model/add-lessons-to-course.model'
import url_slug from '@/lib/utils/url_slug'
import {
  $selectedDifficult,
  difficultDropdownModel,
} from '@/pages/common/dropdowns/courses/tags-dropdown/DificultDropdown/difficult-dropdown'
import {
  $selectedForWho,
  forWhoDropdownModel,
} from '@/pages/common/dropdowns/courses/tags-dropdown/ForWhoDropdown/for-who-dropdown'
import { clearFieldsCourseCreate } from '@/pages/learning/learning-courses/create/courses-create-page.model'

const updateCourses = attach({
  effect: editCoursesFx,
})

const getCourse = attach({
  effect: getCourseFx,
})

export const $isLoadingGetCourse = combine(getCourse.pending, (course) => course)

export const uploadMedia = attach({
  effect: uploadMediaFx,
})

export const getCoverCourse = attach({
  effect: getMediaFx,
})

export const getIconCourse = attach({
  effect: getMediaFx,
})

export const loadCourse = createEvent<number>()
export const $courseId = restore(loadCourse, 0)

export const save = createEvent<void>()
export const clearFieldsCourseEdit = createEvent<void>()

export const clearCourseTitle = createEvent<void>()
export const courseTitleChanged = createEvent<string>()
export const $courseTitle = restore(courseTitleChanged, '').reset(
  clearFieldsCourseEdit,
  clearCourseTitle
)

// true "с заявокой" false "назначенный"
export const courseWithTicketChangedEdit = createEvent<boolean>()
export const $courseWithTicket = restore(courseWithTicketChangedEdit, false).reset(
  clearFieldsCourseEdit
)

// Курс доступен только по ссылке
export const setIsAvailableByLink = createEvent<boolean>()
export const $courseIsAvailableByLink = restore(setIsAvailableByLink, false).reset(
  clearFieldsCourseEdit
)

export const clearCourseSlug = createEvent<void>()
export const courseSlugChanged = createEvent<string>()
export const $courseSlug = restore(courseSlugChanged, '').reset(
  clearFieldsCourseEdit,
  clearCourseSlug
)

export const toggleCompetition = createEvent<boolean>()
export const $competition = restore(toggleCompetition, true).reset(clearFieldsCourseCreate)

export const toggleCommentModule = createEvent<boolean>()
export const $commentModule = restore(toggleCommentModule, true).reset(clearFieldsCourseEdit)

export const courseDescriptionChanged = createEvent<string>()
export const $courseDescription = restore(courseDescriptionChanged, '').reset(clearFieldsCourseEdit)

export const clearCourseCommentCms = createEvent<void>()
export const courseCommentCmsChanged = createEvent<string>()
export const $courseCommentCms = restore(courseCommentCmsChanged, '').reset(
  clearFieldsCourseEdit,
  clearCourseCommentCms
)

export const courseInputTextChanged = createEvent<string>()
export const $courseInputText = restore(courseInputTextChanged, '').reset(clearFieldsCourseEdit)

export const toggleCheckTypeCourse = createEvent<boolean>()
export const $taskCheckTypeCourse = restore(toggleCheckTypeCourse, false).reset(
  clearFieldsCourseEdit
)

export const toggleWithAgreementCourse = createEvent<boolean>()
export const $withAgreementCourse = restore(toggleWithAgreementCourse, false).reset(
  clearFieldsCourseEdit
)

export const toggleTicketsCheckTypeCourse = createEvent<boolean>()
export const $ticketsCheckTypeCourse = restore(toggleTicketsCheckTypeCourse, false).reset(
  clearFieldsCourseEdit
)

export const courseSeoTitleClear = createEvent<void>()
export const courseSeoTitleChanged = createEvent<string>()
export const $courseSeoTitle = restore(courseSeoTitleChanged, '').reset(
  clearFieldsCourseEdit,
  courseSeoTitleClear
)

export const courseSeoDescriptionClear = createEvent<void>()
export const courseSeoDescriptionChanged = createEvent<string>()
export const $courseSeoDescription = restore(courseSeoDescriptionChanged, '').reset(
  clearFieldsCourseEdit,
  courseSeoDescriptionClear
)

export const setQuestionsCourse = createEvent<QuestionsCourseType[]>()
export const $questionsCourse = restore(setQuestionsCourse, []).reset(clearFieldsCourseEdit)

export const setGroupsCourse = createEvent<GroupCourseType[]>()
export const $groupsCourse = restore(setGroupsCourse, []).reset(clearFieldsCourseEdit)

export const changeIfRedirect = createEvent<boolean>()
const $ifRedirect = restore(changeIfRedirect, false)

export const setLessonsCourse = createEvent<LessonsCourseType[]>()

const defaultLessons = {
  id: 0,
  name: '',
  blockId: 0,
  title: '',
  groups: [defaultGroup()],
}

export const $lessonsCourse = restore(setLessonsCourse, [defaultLessons]).reset(
  clearFieldsCourseEdit
)

// Обложка курса
export const uploadCoverCourse = createEvent<FileList>()
export const resetCoverCourseId = createEvent<void>()
export const coverCourseIdChanged = createEvent<number>()
export const $coverCourseId = restore(coverCourseIdChanged, -1).reset(
  resetCoverCourseId,
  clearFieldsCourseEdit
)
export const coverCourseChanged = createEvent<string>()
export const $coverCourse = restore(coverCourseChanged, '').reset(
  resetCoverCourseId,
  clearFieldsCourseEdit
)

// Иконка курса
export const uploadIconCourse = createEvent<FileList>()
export const resetIconCourseId = createEvent<void>()
export const iconCourseIdChanged = createEvent<number>()
export const $iconCourseId = restore(iconCourseIdChanged, -1).reset(
  resetIconCourseId,
  clearFieldsCourseEdit
)
export const iconCourseChanged = createEvent<string>()
export const $iconCourse = restore(iconCourseChanged, '').reset(
  resetIconCourseId,
  clearFieldsCourseEdit
)

export const admissionTextChanged = createEvent<string>()
export const $admissionText = restore(admissionTextChanged, '').reset(clearFieldsCourseEdit)

const $inputsForm = combine({
  name: $courseTitle,
  is_tickets_acceptable: $taskCheckTypeCourse,
  tickets_check_type: $ticketsCheckTypeCourse,
  with_agreement: $withAgreementCourse,
  seo_title: $courseSeoTitle,
  seo_description: $courseSeoDescription,
  is_competition: $competition,
  description: $courseDescription,
  commentary: $courseCommentCms,
  is_comments_active: $commentModule,
  is_available_by_link: $courseIsAvailableByLink,
})

const $withTicketForm = combine(
  $courseInputText,
  $questionsCourse,
  $courseSlug,
  $groupsCourse,
  $courseTitle,
  (courseInputText, questionsCourse, courseSlug, groupsCourse, title) => ({
    intro: courseInputText,
    questions: questionsCourse.map((l) => l.question),
    url_slug: courseSlug ? url_slug(courseSlug) : url_slug(title),
    groups: groupsCourse.map((g) => ({
      id: g.id,
      accept_dt_from: g.accept_dt_from,
      accept_dt_to: g.accept_dt_to,
    })),
  })
)

const $baseFormCourses = combine<any>(
  $courseWithTicket,
  $selectedCourseTaskCheckType,
  $inputsForm,
  $withTicketForm,
  $coverCourseId,
  $iconCourseId,
  classesDropdownModel.store.$selectedItems,
  subjectsDropdownModel.store.$selectedItems,
  $selectedDifficult,
  $selectedForWho,
  $competition,
  $lessonsCourse,
  $courseId,
  $admissionText,
  (
    courseWithTicket: any,
    selectedCourseTaskCheckType: any,
    inputsForm: any,
    withTicketForm: any,
    coverCourseId: any,
    iconCourseId: any,
    selectedClasses: any,
    selectedSubjects: any,
    selectedDifficult: any,
    selectedForWho: any,
    competition: any,
    lessonsCourse: any,
    id: any,
    admissionText: any
  ): UpdateCourseFxParams => ({
    id,
    with_ticket: courseWithTicket,
    classes: selectedClasses.map((cl: any) => Number(cl.name)),
    subjects: selectedSubjects.map((sb: any) => Number(sb.name)),
    difficulty: selectedDifficult?.name || null,
    is_competition: competition,
    students_type: selectedForWho?.name || null,
    lessons: sendLessonsFormat(lessonsCourse.filter((l: any) => l.title)),
    task_check_type: selectedCourseTaskCheckType
      ? selectedCourseTaskCheckType.name === 'Автоматизированный'
      : false,
    ...inputsForm,
    ...(courseWithTicket ? withTicketForm : {}),
    image: coverCourseId !== -1 ? coverCourseId : null,
    icon: iconCourseId !== -1 ? iconCourseId : null,
    admission_text: admissionText,
  })
)

export const $canSaveCourse = combine(
  $courseTitle,
  $courseWithTicket,
  $lessonsCourse,
  $questionsCourse,
  subjectsDropdownModel.store.$selectedItems,
  editCoursesFx.pending,
  $groupsCourse,
  (
    courseTitle,
    courseWithTicket,
    lessonsCourse,
    questionsCourse,
    selectedSubjects,
    loading,
    groupsWithTicket
  ) => {
    const classesSubjects = selectedSubjects.length > 0
    const hasEmptyLessons = lessonsCourse.filter((l) => l.id === 0).length > 0
    if (!courseWithTicket) {
      return (
        courseTitle && courseTitle.length > 0 && classesSubjects && !hasEmptyLessons && !loading
      )
    }
    const hasEmptyGroups =
      groupsWithTicket.filter(
        (l) => l.id === 0 || l.accept_dt_from === null || l.accept_dt_to === null
      ).length > 0
    const isQuestions =
      questionsCourse && questionsCourse.length > 0
        ? questionsCourse.filter((i) => i.question.length > 0).length === questionsCourse.length
        : true
    return (
      !hasEmptyLessons &&
      courseTitle &&
      courseTitle.length > 0 &&
      isQuestions &&
      !loading &&
      !hasEmptyGroups
    )
  }
)

// загрузка медиа на бэк
const uploadCoverCourseFx = createEffect({
  handler: (files: FileList | null): Promise<UploadMediaResponse[]> =>
    Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadMediaResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'image')
            const res = uploadMedia(formData).then((r) => r.body)
            resolve(res)
          })
      )
    ),
})
const uploadIconCourseFx = createEffect({
  handler: (files: FileList | null): Promise<UploadMediaResponse[]> =>
    Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadMediaResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'image')
            const res = uploadMedia(formData).then((r) => r.body)
            resolve(res)
          })
      )
    ),
})

forward({
  from: loadCourse,
  to: getCourse,
})

forward({
  from: clearFieldsCourseEdit,
  to: [
    classesDropdownModel.methods.resetSelectedItems,
    subjectsDropdownModel.methods.resetSelectedItems,
    resetCourseCourseTaskCheck,
  ],
})

forward({
  from: getCourse.doneData.map((res) => res.body),
  to: [
    courseWithTicketChangedEdit.prepend((data) => data.with_ticket),
    setSelectedCourseType.prepend((data) =>
      data.with_ticket ? mapCoursesType[0] : mapCoursesType[1]
    ),
    forWhoDropdownModel.methods.itemChanged.prepend((data) => ({
      name: data?.students_type?.value,
      title: data?.students_type?.display,
    })),
    difficultDropdownModel.methods.itemChanged.prepend((data) => ({
      name: data?.difficulty?.value,
      title: data?.difficulty?.display,
    })),
    classesDropdownModel.methods.setItems.prepend((data) =>
      data.classes.map((s) => ({ name: `${s.id}`, title: s.name }))
    ),
    classesDropdownModel.methods.setSelectedItems.prepend((data) =>
      data.classes.map((s) => ({ name: `${s.id}`, title: s.name }))
    ),
    courseCommentCmsChanged.prepend((data) => data.commentary || ''),
    courseDescriptionChanged.prepend((data) => data.description || ''),
    setGroupsCourse.prepend((data) =>
      data.groups.map((g, index) => ({
        id: g.id,
        blockId: index + 1,
        name: `${g.id}`,
        title: g.name,
        accept_dt_from: g.accept_dt_from,
        accept_dt_to: g.accept_dt_to,
      }))
    ),
    iconCourseIdChanged.prepend((data) => data.icon || -1),
    coverCourseIdChanged.prepend((data) => data.image || -1),
    courseInputTextChanged.prepend((data) => data.intro || ''),
    toggleCheckTypeCourse.prepend((data) => data.is_tickets_acceptable),
    lessonsDropdownModule.methods.setItems.prepend((data) => data.lessons),
    admissionTextChanged.prepend((data) => data.admission_text),
    setLessonsCourse.prepend((data) => {
      // массив уроков если переход был из уроков с функционалом "добавить уроки в курс"
      const addLesson = $addLessonToCourseArr.getState().map((lesson, index) => {
        return {
          ...lesson,
          blockId: index,
          title: lesson.name,
          groups: [defaultGroup()],
        }
      })

      // если уроков не в курсе не из уроков небыло
      if (data.lessons.length === 0 && addLesson.length === 0) {
        return [defaultLessons]
      }

      // форматирование загруженных уроков
      const result = data.lessons.map((les, index) => {
        if (les.groups.length === 0) {
          return { ...les, blockId: index, title: les.name, groups: [defaultGroup()] }
        }
        return {
          ...les,
          blockId: index,
          title: les.name,
          groups: les.groups.map((g, i) => ({
            ...g,
            title: g.name,
            blockId: i,
            name: `${g.id}`,
            start: (g.start && new Date(g.start)) || null,
            end: (g.end && new Date(g.end)) || null,
          })),
        }
      })

      // проверка дублей уроков из двух массивов (в приоритете те что были добавлены)
      const resultIds = result.map((l) => l.id)
      const setLessons = addLesson.filter((l) => !resultIds.includes(l.id))

      // Очистить массив переданых уроков если были
      if (setLessons.length > 0) {
        setArrLessonToCourse([])
      }
      return [...result, ...setLessons]
    }),
    courseTitleChanged.prepend((data) => data.name),
    setQuestionsCourse.prepend((data) =>
      data.questions ? data.questions.map((question) => ({ id: getRandomId(), question })) : []
    ),
    courseSeoDescriptionChanged.prepend((data) => data.seo_description || ''),
    courseSeoTitleChanged.prepend((data) => data.seo_title || ''),
    subjectsDropdownModel.methods.setItems.prepend((data) =>
      data.subjects.map((s) => ({ name: `${s.id}`, title: s.name }))
    ),
    subjectsDropdownModel.methods.setSelectedItems.prepend((data) =>
      data.subjects.map((s) => ({ name: `${s.id}`, title: s.name }))
    ),
    setSelectedCourseTaskCheckType.prepend((data) =>
      data.task_check_type ? mapCourseTaskCheckType[0] : mapCourseTaskCheckType[1]
    ),
    toggleTicketsCheckTypeCourse.prepend((data) => data.tickets_check_type),
    courseSlugChanged.prepend((data) => data.url_slug || ''),
    toggleWithAgreementCourse.prepend((data) => data.with_agreement),
    toggleCommentModule.prepend((data) => data.is_comments_active),
    toggleCompetition.prepend((data) => data.is_competition),
    setIsAvailableByLink.prepend((data) => data.is_available_by_link),
  ],
})

guard({
  clock: uploadCoverCourse,
  filter: (file) => file.length > 0,
  target: [
    uploadCoverCourseFx,
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})
forward({
  from: uploadCoverCourseFx.doneData,
  to: [
    coverCourseIdChanged.prepend((files) => files[0].id),
    successToastEvent('Загрузка завершена'),
  ],
})

guard({
  clock: uploadIconCourse,
  filter: (file) => file.length > 0,
  target: [
    uploadIconCourseFx,
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})
forward({
  from: uploadIconCourseFx.doneData,
  to: [
    iconCourseIdChanged.prepend((files) => files[0].id),
    successToastEvent('Загрузка завершена'),
  ],
})

// агрегация данных медиа
guard({
  clock: coverCourseIdChanged,
  source: $coverCourseId,
  filter: (id) => id !== -1,
  target: getCoverCourse,
})

forward({
  from: getCoverCourse.doneData,
  to: coverCourseChanged.prepend(({ body }) => body.file),
})

guard({
  clock: iconCourseIdChanged,
  source: $iconCourseId,
  filter: (id) => id !== -1,
  target: getIconCourse,
})

forward({
  from: getIconCourse.doneData,
  to: iconCourseChanged.prepend(({ body }) => body.file),
})

sample({
  source: $baseFormCourses,
  clock: save,
  target: updateCourses as any,
})

sample({
  source: $ifRedirect,
  clock: updateCourses.doneData,
  fn: (ifRedirect: boolean) => {
    ifRedirect && navigatePush({ name: 'learning-courses' })
  },
})

forward({
  from: updateCourses.doneData,
  to: successToastEvent('Курс успешно обновлен!'),
})

forward({
  from: updateCourses.failData,
  to: addToast.prepend((data: any) => {
    try {
      return { type: 'error', message: data.body[0] }
    } catch (e) {
      return { type: 'error', message: 'Не удалось обновить курс' }
    }
  }),
})
