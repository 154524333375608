import { config } from '@/config'
import { movingImagesOnTextAddingInsertInputsRules } from '@/pages/common/parts/tasks/moving-images-on-text-input-answer/parts/text-answer-wysiwyg/insert-inputs-rules'

export const url =
  'https://storage.yandexcloud.net/lo-storage-main/frontend-static/ckeditor/ckeditor.js'

export const wysiwygConfig = {
  title: false,
  language: 'ru',
  extraPlugins:
    'uploadimage, divarea, html5video, widget, widgetselection, clipboard, lineutils, video, videodetector, browseServer',
  removePlugins: 'easyimage, cloudservices, exportpdf',
  uploadUrl: `${config.BACKEND_URL}/api/media-app/media/upload/`,
  filebrowserUploadUrl: `${config.BACKEND_URL}/api/media-app/media/upload/`,
  pasteUploadFileApi: `${config.BACKEND_URL}/api/media-app/media/upload/`,
  allowedContent: true,
  skin: 'bootstrapck',
  toolbar: [
    [
      'Bold',
      'Italic',
      'Underline',
      'Strike',
      'Subscript',
      'Superscript',
      'JustifyLeft',
      'JustifyCenter',
      'JustifyRight',
      'Font',
      'TextColor',
      'BGColor',
      'FontSize',
      'Format',
    ],
    '/',
    [
      'BulletedList',
      'NumberedList',
      'Link',
      'Table',
      'Image',
      'VideoDetector',
      'Maximize',
      'Source',
      'browseServer',
    ],
  ],
  removeButtons: '',
  editorplaceholder: '',
  pasteFromWordRemoveFontStyles: true,
}

export const enableRules = (editor: any): void => {
  const { writer } = editor.dataProcessor
  const rules = {
    indent: false,
    breakBeforeOpen: false,
    breakAfterOpen: false,
    breakBeforeClose: false,
    breakAfterClose: false,
  }

  movingImagesOnTextAddingInsertInputsRules(editor)

  writer.setRules('p', rules)
  writer.setRules('ul', rules)
  writer.setRules('li', rules)
  writer.setRules('ol', rules)
  writer.setRules('blockquote', rules)
  writer.setRules('table', rules)
  writer.setRules('theader', rules)
  writer.setRules('tbody', rules)
  writer.setRules('tr', rules)
  writer.setRules('td', rules)
  writer.setRules('caption', rules)
  writer.setRules('hr', rules)
  writer.setRules('div', rules)
  writer.setRules('address', rules)
  writer.setRules('pre', rules)
}

export const IMAGE_MAX_WIDTH = 620
