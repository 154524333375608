import { attach, combine, createEffect, createEvent, createStore, forward } from 'effector-root'
import {
  ChangeFieldType,
  ElementTypeNames,
  formAssignmentBlock,
  LessonElementsType,
  TaskTypeNames,
} from '@/pages/learning/learning-lessons/create/model/types'
import { spread } from 'patronum'
import { uploadMediaFx } from '@/features/api/media/upload-media'
import { addToast, successToastEvent } from '@/features/toasts/toasts.model'
import { getLessonByIdFx } from '@/features/api/learning/lessons/lesson-by-id-end-points'
import { subjectsDropdownModel } from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import { themesDropdownModule } from '@/pages/common/dropdowns/themes-assigment-tree/themes-assigment-dropdown.model'
import { LessonsAssignBlock } from '@/pages/learning/learning-lessons/create/model/assignment-block.model'
import { UploadMediaResponse } from '@/features/api/media/types'

export const uploadCoverLesson = createEvent<FileList>()

export const deleteLessonCover = createEvent()

export const resetFields = createEvent()

export const uploadMedia = attach({
  effect: uploadMediaFx,
})

export const $uploadedFiles = createStore(null).reset(resetFields)

const uploadLessonCoverFx = createEffect({
  handler: (files: FileList | null): Promise<UploadMediaResponse[]> =>
    Promise.all(
      Array.from(files || []).map(
        (file) =>
          new Promise<UploadMediaResponse>((resolve) => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('file_type', 'image')
            const res = uploadMedia(formData).then((r) => r.body)
            resolve(res)
          })
      )
    ),
})

export const $lessonCoverId = createStore<number | null>(null)
  .on(uploadLessonCoverFx.doneData, (_, res) => res[0].id)
  .reset(deleteLessonCover, resetFields)

export const $lessonCover = createStore('')
  .on(uploadLessonCoverFx.doneData, (_, res) => res[0].file)
  .reset(deleteLessonCover, resetFields)

forward({
  from: uploadCoverLesson,
  to: [
    uploadLessonCoverFx,
    addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
  ],
})

export const changeField = createEvent<ChangeFieldType>()
export const $lessonName = createStore('').reset(resetFields)
export const $lessonNumber = createStore('').reset(resetFields)
export const $lessonNotShowAnswers = createStore(false).reset(resetFields)
export const setDuration = createEvent<null | number>()

export const $lessonIsDuration = createStore(false)
  .on(setDuration, (_, res) => !!res)
  .reset(resetFields)
export const $lessonDuration = createStore<null | number>(null)
  .on(setDuration, (_, res) => res)
  .reset(resetFields)

forward({
  from: resetFields,
  to: [
    subjectsDropdownModel.methods.resetItem,
    themesDropdownModule.methods.resetItem,
    LessonsAssignBlock.methods.resetFields,
  ],
})

export const $selectedTheme = createStore('').reset(resetFields)
export const $instruction = createStore('').reset(resetFields)
export const $lessonResult = createStore('').reset(resetFields)

const defineStateAfterAdd = (state: LessonElementsType[], payload: LessonElementsType) => {
  const newState = [...state]
  const currentElementID = newState.findIndex((item) => item.blockId === payload.blockId)
  if (currentElementID > -1) {
    newState[currentElementID] = {
      ...newState[currentElementID],
      ...payload,
    }
  }
  return newState
}

const defineStateAfterDelete = (state: LessonElementsType[], id: number) => {
  const deleteElement = state.find((block) => block.blockId === id)
  if (deleteElement && deleteElement.element_type === ElementTypeNames.assignment) {
    LessonsAssignBlock.methods.deleteAssignmentBlock(deleteElement?.assigmentBlockId || 0)
  }
  return [...state]
    .filter((element, index) => index !== id)
    .map((element, i) => ({ ...element, blockId: i }))
}

export const changeBlock = createEvent<LessonElementsType>()
export const addBlock = createEvent<ElementTypeNames>()
export const setBlocks = createEvent<LessonElementsType[]>()
export const loadBlocks = createEvent<LessonElementsType[]>()
export const deleteBlock = createEvent<number>()

export const $lessonsElementBlocks = createStore<LessonElementsType[]>([])
  .on(setBlocks, (state, payload) => payload)
  .on(changeBlock, (state, payload) => defineStateAfterAdd(state, payload))
  .on(addBlock, (state, element_type) => {
    const newState = [...state]
    if (element_type === 'assignment') {
      const assignmentBloksLength = state.filter(
        (blocks) => blocks.element_type === 'assignment'
      ).length
      newState.push({
        blockId: state.length,
        element_type,
        assigmentBlockId: assignmentBloksLength,
      })
      LessonsAssignBlock.methods.addAssignmentBlock({ id: assignmentBloksLength })
    } else {
      newState.push({ blockId: state.length, element_type })
    }
    return newState
  })
  .on(loadBlocks, (state, payload) => {
    const newState: LessonElementsType[] = []
    payload.forEach((block, index) => {
      if (block.element_type === ElementTypeNames.assignment) {
        const assignmentBloksLength = block.id || index
        newState.push({
          ...block,
          blockId: block.id,
          assigmentBlockId: assignmentBloksLength,
        })
        // добавление блока с заданиями
        // установка информации о выбранном задании в блок
        const fieldName = `${block.task_type || TaskTypeNames.test}_assignment`
        const task = {
          wording: block[fieldName].wording,
          difficulty: block[fieldName].difficulty,
          score: block[fieldName].score,
          id: block[fieldName].id,
          type: block[fieldName].type,
          blockId: assignmentBloksLength,
          useInternalScores: block[fieldName].has_internal_scores
            ? block[fieldName].use_internal_scores
            : false,
        }
        const AssignmentBlockData = {
          id: assignmentBloksLength,
          taskType: block.task_type || TaskTypeNames.test,
          task,
          useTaskScore: block.use_task_score,
          score: !block.use_task_score ? String(block.score || 0) : '',
        }
        LessonsAssignBlock.methods.addAssignmentBlock(AssignmentBlockData)
      } else if (block.element_type === ElementTypeNames.file) {
        newState.push({ ...block, file: block.media ? block.media : null, blockId: block.id })
      } else {
        newState.push({ ...block, blockId: block.id })
      }
    })
    return newState
  })
  .on(deleteBlock, (state, id) => defineStateAfterDelete(state, id))
  .reset(resetFields)

export const $disabledSaveButtons = combine(
  $lessonName,
  $lessonNumber,
  subjectsDropdownModel.store.$item,
  $selectedTheme,
  $lessonsElementBlocks,
  (name, number, subject, theme, blocks) => {
    let isValidBlocks = true
    blocks.forEach((block) => {
      // валидация блока с ссылкой на ютуб
      if (
        block.element_type === ElementTypeNames.link &&
        (!block.link || (!!block.link && block.link.length < 2))
      ) {
        isValidBlocks = false
      }

      // валидация блока 'YouTube-ролик'
      if (
        block.element_type === ElementTypeNames.text &&
        (!block.text || (!!block.text && block.text.length < 2))
      ) {
        isValidBlocks = false
      }

      // валидация блока с 'Файл с описанием'
      if (
        block.element_type === ElementTypeNames.file &&
        (!block.description || (!!block.description && block.description.length < 2) || !block.file)
      ) {
        isValidBlocks = false
      }
    })

    return !name || !number || !subject || !theme || !isValidBlocks
  }
)

const formatLessonElements = (
  ElementBlocks: LessonElementsType[],
  FormAssignmentBlock: formAssignmentBlock
): LessonElementsType[] => {
  return ElementBlocks.map((inputBlock) => {
    const block = JSON.parse(JSON.stringify(inputBlock))
    const el_id = block?.id
    if (el_id) {
      block.el_id = el_id
    }
    delete block.blockId
    if (block.element_type === ElementTypeNames.assignment) {
      const task_type = FormAssignmentBlock.task_type[block.assigmentBlockId] || TaskTypeNames.test
      const task_id = FormAssignmentBlock.task[block.assigmentBlockId]?.id || 0
      const use_task_score = FormAssignmentBlock.use_task_score[block.assigmentBlockId] || false
      const score =
        FormAssignmentBlock.score[block.assigmentBlockId] && !use_task_score
          ? FormAssignmentBlock.score[block.assigmentBlockId]
          : 0
      return {
        ...block,
        task_type,
        use_task_score,
        score,
        task_id,
        [`${task_type}_assignment_id`]: task_id,
      }
    }
    if (block.element_type === ElementTypeNames.file) {
      return {
        ...block,
        media_id: block.file?.id || null,
      }
    }
    return block
  })
}

const $baseFormInputs = combine(
  $lessonName,
  $lessonNumber,
  $instruction,
  $lessonResult,
  $lessonNotShowAnswers,
  $lessonDuration,
  $lessonIsDuration,
  (name, number, instruction, results, not_show_answers, lessonDuration, lessonIsDuration) => ({
    name,
    number: Number(number),
    instruction,
    results,
    not_show_answers,
    duration: lessonIsDuration ? lessonDuration : 0,
  })
)

export const $formWithoutTaskBlock = combine(
  $baseFormInputs,
  subjectsDropdownModel.store.$item,
  $selectedTheme,
  $lessonsElementBlocks,
  LessonsAssignBlock.store.$sendFormAssignmentBlock,
  $lessonCoverId,
  (baseInputs, subject, theme, lesson_elements, FormAssignmentBlock, cover) => ({
    ...baseInputs,
    subject_id: Number(subject?.name),
    theme_id: Number(theme),
    lesson_elements: formatLessonElements(lesson_elements, FormAssignmentBlock),
    cover_id: cover || null,
  })
)

spread({
  source: changeField.map((data) => {
    const obj = {}
    obj[data.type] = data.value
    return obj
  }),
  targets: {
    lessonName: $lessonName,
    lessonNumber: $lessonNumber,
    subject: subjectsDropdownModel.store.$item,
    theme: $selectedTheme,
    instruction: $instruction,
    lessonResult: $lessonResult,
    notShowAnswers: $lessonNotShowAnswers,
    lessonIsDuration: $lessonIsDuration,
    lessonDuration: $lessonDuration,
  },
})

spread({
  source: getLessonByIdFx.doneData.map((res) => ({
    ...res.body,
    theme: res.body.theme.id,
    subject: res.body.subject.id,
    themeDropdown: res.body.theme,
    subjectDropdown: res.body.subject,
    lesson_elements: res.body.lesson_elements,
    not_show_answers: res.body.not_show_answers,
    cover: res.body.cover?.file || null,
    duration: res.body.duration,
  })),
  targets: {
    name: $lessonName,
    number: $lessonNumber,
    subject: subjectsDropdownModel.store.$item,
    subjectDropdown: subjectsDropdownModel.methods.itemChanged.prepend(
      (payload: { id: number; name: string; subject_id: number }) => ({
        name: `${payload.id}`,
        title: payload.name,
      })
    ),
    theme: $selectedTheme,
    themeDropdown: themesDropdownModule.methods.itemChanged.prepend(
      (payload: { id: number; name: string; subject_id: number }) => ({
        name: `${payload.id}`,
        title: payload.name,
      })
    ),
    instruction: $instruction,
    results: $lessonResult,
    lesson_elements: loadBlocks,
    cover: $lessonCover,
    not_show_answers: $lessonNotShowAnswers,
    duration: setDuration,
  },
})

forward({
  from: uploadMedia,
  to: addToast.prepend(() => ({ type: 'loading', message: 'Идет загрузка файла(ов)' })),
})

forward({
  from: uploadMedia.done,
  to: successToastEvent('Загрузка завершена'),
})
