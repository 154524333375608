import { createFilter } from '@/pages/common/filter-dropdown/create-filter'
import { createEvent, createStore, forward, guard, sample, split } from 'effector-root'
import {
  ElementsDropdownType,
  ElementTypeNames,
} from '@/pages/learning/learning-lessons/create/model/types'
import { DropdownItem } from '@/pages/common/types'
import { addBlock as addBlockToLessons } from '@/pages/learning/learning-lessons/create/model/form-fileds.model'
import {
  addIntroBlock as addBlockToExamTests,
  resetFields,
} from '@/pages/exams/full-time-exam-test/create/model/form-fields.model'

export type PageType = 'lessons' | 'exam-tests'

export const elementsDropdownModel = createFilter()

const addElement = createEvent<{ element: ElementTypeNames; type: PageType }>()

export const selectBlockElement = createEvent<PageType>()

const addBlockToPage = sample({
  source: elementsDropdownModel.store.$item,
  clock: selectBlockElement,
  fn: (item: DropdownItem | null, pageType) => ({ type: pageType, element: item?.name || '' }),
})

forward({
  from: addBlockToPage,
  to: [elementsDropdownModel.methods.resetItem, elementsDropdownModel.methods.resetSearchString],
})

forward({
  from: resetFields,
  to: elementsDropdownModel.methods.resetDropdown,
})

guard({
  source: addBlockToPage,
  filter: (block) => !!block.element,
  target: addElement.prepend((block: { element: string; type: PageType }) => {
    switch (block.element) {
      case ElementTypeNames.file:
        return { element: ElementTypeNames.file, type: block.type }
      case ElementTypeNames.assignment:
        return { element: ElementTypeNames.assignment, type: block.type }
      case ElementTypeNames.link:
        return { element: ElementTypeNames.link, type: block.type }
      case ElementTypeNames.answer_text_field:
        return { element: ElementTypeNames.answer_text_field, type: block.type }
      case ElementTypeNames.answer_file_field:
        return { element: ElementTypeNames.answer_file_field, type: block.type }
      default:
        return { element: ElementTypeNames.text, type: block.type }
    }
  }),
})

export const $elements = createStore<ElementsDropdownType[]>([
  { name: ElementTypeNames.text, title: 'Текст с изображением' },
  { name: ElementTypeNames.assignment, title: 'Задание' },
  { name: ElementTypeNames.link, title: 'YouTube-ролик' },
  { name: ElementTypeNames.answer_text_field, title: 'Поле ввода решения задания' },
  { name: ElementTypeNames.answer_file_field, title: 'Поле для загрузки файла' },
  { name: ElementTypeNames.file, title: 'Файл с описанием' },
])

split({
  source: addElement,
  match: {
    lessons: (payload) => payload.type === 'lessons',
    examTests: (payload) => payload.type === 'exam-tests',
  },
  cases: {
    lessons: addBlockToLessons.prepend(
      (block: { element: ElementTypeNames; type: PageType }) => block.element
    ),
    examTests: addBlockToExamTests.prepend(
      (block: { element: ElementTypeNames; type: PageType }) => block.element
    ),
  },
})
