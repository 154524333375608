import { attach, combine, createEvent, forward, restore, sample } from 'effector-root'
import { every } from 'patronum'
import {
  $selectedSubject,
  setSelectedSubject,
  subjectsDropdownModel,
} from '@/pages/common/dropdowns/subject/subjects-dropdown.model'
import {
  $selectedClass,
  classesDropdownModule,
  setSelectedClass,
} from '@/pages/common/dropdowns/class/classes-dropdown.model'
import { DEFAULT_ID } from '@/pages/common/constants'
import { ResourceType } from '@/features/api/media/types'
import { getResourceFx } from '@/features/api/media/get-resource'
import {
  $selectedTheme,
  setThemeToSelect,
  themesDropdownModule,
} from '@/pages/common/dropdowns/themes-tree/themes-dropdown.model'
import {
  $fileData,
  uploadFileFx,
  fileDataChanged,
} from '@/pages/dictionary/resources/edit/parts/file-upload/file-upload.model'
import {
  $selectedType,
  setSelectedType,
  typeDropdownModule,
} from '@/pages/dictionary/resources/edit/parts/type/type-dropdown.model'
import { errorToastEvent, successToastEvent } from '@/features/toasts/toasts.model'
import { isLinkValid } from '@/lib/validators/url'
import { updateResourceFx } from '@/features/api/media/update-resource'
import { createError } from '@/lib/effector/error-generator'
import { mapResourceType } from '@/pages/dictionary/constants'
import { navigatePush } from '@/features/navigation/navigationMethods'
import { getThemeFx } from '@/features/api/subject/get-theme'

const getThemeData = attach({
  effect: getThemeFx,
})

const updateResourceDataFx = attach({
  effect: updateResourceFx,
})

export const getResourceToUpdate = attach({
  effect: getResourceFx,
})

const updateResource = createEvent<void>()

export const clearFields = createEvent<void>()

export const edit = createEvent<void>()
const checkIfResourceCanBeSend = createEvent<void>()

export const redirectAfterSaveChanged = createEvent<boolean>()
const $redirectAfterSave = restore(redirectAfterSaveChanged, false)

export const resourceDescriptionChanged = createEvent<string>()
export const $resourceDescription = restore(resourceDescriptionChanged, '').reset(clearFields)

export const linkChanged = createEvent<string>()
export const resetLink = createEvent<void>()
export const $link = restore(linkChanged, '').reset(clearFields)

const showError = createEvent<void>()

forward({
  from: clearFields,
  to: [
    classesDropdownModule.methods.resetDropdown,
    subjectsDropdownModel.methods.resetDropdown,
    themesDropdownModule.methods.resetDropdown,
    fileDataChanged.prepend(() => null),
  ],
})

export const $canSetTheme = every({
  predicate: (value) => value !== null,
  stores: [$selectedSubject, $selectedClass],
})

export const $formToSend = combine({
  id: DEFAULT_ID,
  text: $resourceDescription,
  link: $link,
  theme: $selectedTheme.map((data) => (data ? +data.name : DEFAULT_ID)),
  media_id: $fileData.map((data) => (data ? data.id : null)),
  resource_type: $selectedType.map((data) => (data ? data.name : '')),
})

sample({
  clock: getResourceToUpdate.done,
  source: getResourceFx.doneData.map((data) => data.body),
  fn: (resource: ResourceType) => {
    getThemeData(resource.theme!)
    linkChanged(resource.link!)
    typeDropdownModule.methods.itemChanged({
      name: resource.resource_type,
      title: mapResourceType[resource.resource_type],
    })
    setSelectedType({ name: resource.resource_type, title: '' })
    $formToSend.map((el) => (el.id = resource.id))
    resourceDescriptionChanged(resource.text!)
    fileDataChanged(resource.media!)
  },
})

sample({
  clock: getThemeData.doneData.map(({ body }) => body),
  fn: (theme) => {
    subjectsDropdownModel.methods.itemChanged({
      name: `${theme.subject.id}`,
      title: theme.subject.name,
    })
    setSelectedSubject({
      name: `${theme.subject.id}`,
      title: theme.subject.name,
    })
    theme.study_year &&
      classesDropdownModule.methods.itemChanged({
        name: `${theme.study_year.id}`,
        title: theme.study_year.name,
      })
    theme.study_year &&
      setSelectedClass({
        name: `${theme.study_year.id}`,
        title: theme.study_year.name,
      })
    setThemeToSelect({
      name: `${theme.id}`,
      title: theme.name || '',
    })
  },
})

export const $typeErrorModule = createError()

export const $themeErrorModule = createError()

export const $descriptionErrorModule = createError()

export const $linkErrorModule = createError()

export const $fileErrorModule = createError()

const resetErrors = createEvent<void>()

forward({
  from: resetErrors,
  to: [
    $typeErrorModule.methods.resetError,
    $themeErrorModule.methods.resetError,
    $descriptionErrorModule.methods.resetError,
    resetLink,
    $fileErrorModule.methods.resetError,
  ],
})

forward({
  from: edit,
  to: checkIfResourceCanBeSend,
})

sample({
  source: $formToSend,
  clock: checkIfResourceCanBeSend,
  fn: (obj) => {
    let errors = 0
    if (!obj.resource_type) {
      $typeErrorModule.methods.setError(true)
      errors += 1
    }
    if (obj.theme === DEFAULT_ID) {
      $themeErrorModule.methods.setError(true)
      errors += 1
    }
    if (obj.resource_type === 'text' && !obj.text.trim().length) {
      $descriptionErrorModule.methods.setError(true)
      errors += 1
    } else if (
      (obj.resource_type === 'link' || obj.resource_type === 'video') &&
      (!obj.link.trim().length || !isLinkValid(obj.link))
    ) {
      $linkErrorModule.methods.setError(true)
      errors += 1
    } else if (obj.resource_type === 'file' && !obj.media_id) {
      $fileErrorModule.methods.setError(true)
      errors += 1
    }
    if (errors === 0) updateResource()
    else if (errors > 0) showError()
  },
})

forward({
  from: showError,
  to: errorToastEvent('Необходимо заполнить все обязательные поля'),
})

sample({
  clock: updateResource,
  source: $formToSend,
  target: updateResourceDataFx,
})

const $ifRedirect = sample({
  clock: updateResourceDataFx,
  source: $redirectAfterSave,
  fn: (isRedirect: boolean) => isRedirect,
})

sample({
  source: $ifRedirect,
  clock: updateResourceDataFx.doneData.map((data) => data.body.id),
  fn: (ifRedirect: boolean, id: number) => {
    if (ifRedirect) navigatePush({ name: 'resources-list' })
    else getResourceToUpdate(id)
  },
  target: successToastEvent('Ресурс успешно обновлен!'),
})

forward({
  from: setSelectedType,
  to: resetErrors,
})
forward({
  from: typeDropdownModule.methods.itemChanged,
  to: $typeErrorModule.methods.resetError,
})

forward({
  from: themesDropdownModule.methods.itemChanged,
  to: $themeErrorModule.methods.resetError,
})

forward({
  from: resourceDescriptionChanged,
  to: $descriptionErrorModule.methods.resetError,
})

forward({
  from: linkChanged,
  to: $linkErrorModule.methods.resetError,
})

forward({
  from: uploadFileFx,
  to: $fileErrorModule.methods.resetError,
})
